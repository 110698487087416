const tracks = [
  {
    artist: 'Bonnie Bailey',
    image: '/images/album-ever-after.jpg',
    title: 'Ever After',
    track: new Audio('/audio/ever-after.mp3'),
  },
  {
    artist: 'Lo Fidelity Allstars',
    image: '/images/album-battleflag.jpg',
    title: 'Battlefield',
    track: new Audio('/audio/battleflag.mp3'),
  },
  {
    artist: 'ZZ Top',
    image: '/images/album-zz-top.jpg',
    title: 'Sharp Dressed Man',
    track: new Audio('/audio/sharp-dressed-man.mp3'),
  },
  {
    artist: 'Mitiska',
    image: '/images/album-mitiska.jpg',
    title: 'Blue',
    track: new Audio('/audio/blue.mp3'),
  },
  {
    artist: 'Theory of a Deadman',
    image: '/images/album-theory-of-a-deadman.jpg',
    title: 'Bad Girlfriend',
    track: new Audio('/audio/bad-girlfriend.mp3'),
  },
  {
    artist: 'Phil Collins',
    image: '/images/album-phil-collins.jpg',
    title: 'In The Air Tonight',
    track: new Audio('/audio/in-the-air-tonight.mp3'),
  },
  {
    artist: 'Mötley Crüe',
    image: '/images/album-motley-crue.jpg',
    title: 'Kickstart my Heart',
    track: new Audio('/audio/kickstart-my-heart.mp3'),
  },
  {
    artist: 'Rob Dougan',
    image: '/images/album-rob-dougan.jpg',
    title: 'Clubbed To Death',
    track: new Audio('/audio/clubbed-to-death.mp3'),
  },
  // {
  //   artist: 'Tenebrax',
  //   image: '/images/album-heresy.jpg',
  //   title: 'Heresy',
  //   track: new Audio('/audio/heresy.mp3'),
  // },
  // {
  //   artist: 'Orbital',
  //   image: '/images/album-orbital.jpg',
  //   title: 'Halcyon and On and On',
  //   track: new Audio('/audio/halcyon-and-on-and-on.mp3'),
  // },
  // {
  //   artist: 'Olivɘr',
  //   image: '/images/album-why.jpg',
  //   title: 'Why',
  //   track: new Audio('/audio/why.mp3'),
  // },
  // {
  //   artist: 'Naux Faux',
  //   image: '/images/album-psyopia.jpg',
  //   title: 'Psyopia',
  //   track: new Audio('/audio/psyopia.mp3'),
  // },
  // {
  //   artist: 'Minnesota',
  //   image: '/images/album-subterranean.jpg',
  //   title: 'Subterranean',
  //   track: new Audio('/audio/subterranean.mp3'),
  // },
  // {
  //   artist: 'Steve Angello',
  //   image: '/images/album-flashing-lights.jpg',
  //   title: 'Flashing Lights',
  //   track: new Audio('/audio/flashing-lights.mp3'),
  // },
  // {
  //   artist: 'Andy Duguid',
  //   image: '/images/album-chic.jpg',
  //   title: 'C.H.I.C.',
  //   track: new Audio('/audio/chic.mp3'),
  // },
  // {
  //   artist: 'Waitwhat',
  //   image: '/images/album-alive.jpg',
  //   title: 'Alive',
  //   track: new Audio('/audio/alive.mp3'),
  // },
  // {
  //   artist: 'Two Ways',
  //   image: '/images/album-vector-to-the-heavens.jpg',
  //   title: 'Vector To The Heavens',
  //   track: new Audio('/audio/vector-to-the-heavens.mp3'),
  // },
  // {
  //   artist: 'Ólafur Arnalds',
  //   image: '/images/album-near-light.jpg',
  //   title: 'Near Light',
  //   track: new Audio('/audio/near-light.mp3'),
  //   tooltip: 'My personal favorite...',
  // },
];

export default tracks;

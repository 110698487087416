import React from 'react';
import PropTypes from 'prop-types';
import s from './Title.module.scss';

const Title = ({ copy, className }) => <h1 className={`${s.title} ${className && className}`}>{copy}</h1>;

Title.defaultProps = {
  className: null,
};

Title.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.string.isRequired,
};

export default Title;

import { fromJS } from 'immutable';

const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
const UPDATE_SENDING_EMAIL = 'UPDATE_SENDING_EMAIL';
const UPDATE_SENDING_EMAIL_ERROR = 'UPDATE_SENDING_EMAIL_ERROR';

export const updateNotifications = (number) => ({
  type: UPDATE_NOTIFICATIONS,
  number,
});

export const updateSendingEmail = (isSending) => ({
  type: UPDATE_SENDING_EMAIL,
  isSending,
});

export const updateSendingEmailError = (error) => ({
  type: UPDATE_SENDING_EMAIL_ERROR,
  error,
});

const initialState = fromJS({
  date: new Date(),
  error: null,
  isSending: false,
  status: 'Updated Just Now',
  notifications: 0,
});

export default function mailReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return state.merge({
        notifications: action.number,
      });
    case UPDATE_SENDING_EMAIL:
      return state.merge({
        isSending: action.isSending,
        status: action.isSending ? 'Sending...' : initialState.get('status'),
      });
    case UPDATE_SENDING_EMAIL_ERROR:
      return state.merge({
        error: action.error,
        isSending: false,
        status: action.error ? 'Unable To Send Message' : initialState.get('status'),
      });
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import Bio from './Bio';

export default class BioVM extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  constructor() {
    super();
    this.Component = this.createViewModel();
  }

  mapStateToProps() {
    return {};
  }

  createViewModel() {
    return React.memo(Bio);
  }

  render() {
    return <this.Component />;
  }
}

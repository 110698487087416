import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';

const MainContainer = (props) => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>{props.children}</div>
  </div>
);

MainContainer.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(MainContainer);

import { fromJS } from 'immutable';

const TOGGLE_APP_STATE = 'TOGGLE_APP_STATE';
const UPDATE_POWER = 'UPDATE_POWER';
const UPDATE_SCREEN_POSITION = 'UPDATE_SCREEN_POSITION';

export const updateScreenPosition = (screenPosition) => ({
  type: UPDATE_SCREEN_POSITION,
  screenPosition,
});

export const updatePower = (power) => ({
  type: UPDATE_POWER,
  power,
});

export const toggleAppState = ({ active, component }) => ({
  type: TOGGLE_APP_STATE,
  active,
  view: active ? component : 'home',
});

const initialState = fromJS({
  appIsActive: false,
  screenPosition: null,
  view: 'home',
  poweredOn: false,
});

export default function Iphone(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_APP_STATE:
      return state.merge({
        appIsActive: action.active,
        view: action.view,
      });
    case UPDATE_POWER:
      return state.merge({
        poweredOn: action.power,
      });
    case UPDATE_SCREEN_POSITION:
      return state.merge({
        screenPosition: action.screenPosition,
      });
    default:
      return state;
  }
}

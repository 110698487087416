import React from 'react';
import { Image } from '../../components';
import s from './Bio.module.scss';

class Bio extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }

  render() {
    return (
      <section className={s.container} ref={this.ref}>
        <div className={s.imgContainer}>
          <Image
            alt="justin woodward"
            className={s.img}
            src="/images/justin-woodward.jpg"
            webp="/images/justin-woodward.webp"
          />
        </div>
        <h1 className={s.title}>
          <div className={s.name}>Justin Woodward</div>
          <div className={s.position}>Innovator</div>
        </h1>
        <hr className={s.break} />
        <p className={s.body}>
          Justin is a self-managed mind who negotiates with reality and understands the importance of knowing the
          holistic vision of a company and how to reflect and propel its vision in all aspects.
        </p>
      </section>
    );
  }
}

export default Bio;

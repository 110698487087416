import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import Music from './Music';
import tracks from '../../config/music-tracks';

// const tracks = [
//   {
//     artist: 'Bonnie Bailey',
//     image: '/images/album-ever-after.jpg',
//     title: 'Ever After',
//     track: new Audio('/audio/ever-after.mp3'),
//   },
//   {
//     artist: 'Lo Fidelity Allstars',
//     image: '/images/album-battleflag.jpg',
//     title: 'Battlefield',
//     track: new Audio('/audio/battleflag.mp3'),
//   },
//   {
//     artist: 'ZZ Top',
//     image: '/images/album-zz-top.jpg',
//     title: 'Sharp Dressed Man',
//     track: new Audio('/audio/sharp-dressed-man.mp3'),
//   },
//   {
//     artist: 'Mitiska',
//     image: '/images/album-mitiska.jpg',
//     title: 'Blue',
//     track: new Audio('/audio/blue.mp3'),
//   },
//   {
//     artist: 'Theory of a Deadman',
//     image: '/images/album-theory-of-a-deadman.jpg',
//     title: 'Bad Girlfriend',
//     track: new Audio('/audio/bad-girlfriend.mp3'),
//   },
//   {
//     artist: 'Phil Collins',
//     image: '/images/album-phil-collins.jpg',
//     title: 'In The Air Tonight',
//     track: new Audio('/audio/in-the-air-tonight.mp3'),
//   },
//   {
//     artist: 'Mötley Crüe',
//     image: '/images/album-motley-crue.jpg',
//     title: 'Kickstart my Heart',
//     track: new Audio('/audio/kickstart-my-heart.mp3'),
//   },
//   {
//     artist: 'Rob Dougan',
//     image: '/images/album-rob-dougan.jpg',
//     title: 'Clubbed To Death',
//     track: new Audio('/audio/clubbed-to-death.mp3'),
//   },
//   // {
//   //   artist: 'Olivɘr',
//   //   image: '/images/album-why.jpg',
//   //   title: 'Why',
//   //   track: new Audio('/audio/why.mp3'),
//   // },
//   // {
//   //   artist: 'Naux Faux',
//   //   image: '/images/album-psyopia.jpg',
//   //   title: 'Psyopia',
//   //   track: new Audio('/audio/psyopia.mp3'),
//   // },
//   // {
//   //   artist: 'Minnesota',
//   //   image: '/images/album-subterranean.jpg',
//   //   title: 'Subterranean',
//   //   track: new Audio('/audio/subterranean.mp3'),
//   // },
//   // {
//   //   artist: 'Steve Angello',
//   //   image: '/images/album-flashing-lights.jpg',
//   //   title: 'Flashing Lights',
//   //   track: new Audio('/audio/flashing-lights.mp3'),
//   // },
//   // {
//   //   artist: 'Andy Duguid',
//   //   image: '/images/album-chic.jpg',
//   //   title: 'C.H.I.C.',
//   //   track: new Audio('/audio/chic.mp3'),
//   // },
//   // {
//   //   artist: 'Waitwhat',
//   //   image: '/images/album-alive.jpg',
//   //   title: 'Alive',
//   //   track: new Audio('/audio/alive.mp3'),
//   // },
//   // {
//   //   artist: 'Two Ways',
//   //   image: '/images/album-vector-to-the-heavens.jpg',
//   //   title: 'Vector To The Heavens',
//   //   track: new Audio('/audio/vector-to-the-heavens.mp3'),
//   // },
//   // {
//   //   artist: 'Ólafur Arnalds',
//   //   image: '/images/album-near-light.jpg',
//   //   title: 'Near Light',
//   //   track: new Audio('/audio/near-light.mp3'),
//   //   // tooltip: 'My personal favorite...',
//   // },
// ];

export default class MusicVM extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      tracks,
      trackCount: tracks.length,
      playing: false,
      random: false,
      nowPlaying: {
        artist: null,
        image: '/images/album.jpg',
        title: 'Not Playing',
        index: 0,
      },
    };

    this.setAudioListners();
    this.Component = this.createViewModel();
    this.playNextSong = this.playNextSong.bind(this);
    this.playRandomSong = this.playRandomSong.bind(this);
    this.playSong = this.playSong.bind(this);
    this.stopSong = this.stopSong.bind(this);
  }

  setAudioListners() {
    this.state.tracks.forEach(({ track }) => {
      track.onended = () => this.playNextSong();
    });
  }

  createViewModel() {
    return React.memo(Music);
  }

  stopSong(clear = false) {
    const { track } = this.state.nowPlaying;

    if (!track) return;

    track.pause();
    if (clear) track.currentTime = 0;

    this.setState({ playing: false });
  }

  playSong(index, clear = false) {
    this.stopSong(clear);
    this.state.tracks[index].track.play();
    this.setState({
      playing: true,
      nowPlaying: {
        ...this.state.tracks[index],
        index,
      },
    });
  }

  playNextSong() {
    const nextIndex = this.state.nowPlaying.index + 1;
    this.playSong(nextIndex >= this.state.trackCount ? 0 : nextIndex, true);
  }

  playRandomSong() {
    const index = Math.floor(Math.random() * (this.state.trackCount - 1 + 1)) + 1 - 1;

    if (index === this.state.nowPlaying.index) return this.playRandomSong();
    this.playSong(index, true);
  }

  render() {
    return (
      <this.Component
        {...this.state}
        playNextSong={this.playNextSong}
        playRandomSong={this.playRandomSong}
        playSong={this.playSong}
        stopSong={this.stopSong}
      />
    );
  }
}

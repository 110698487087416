import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

function HomeButton(props) {
  if (props.active) {
    return <button className={`${s.homeBtn} ${props.invert ? s.invert : ''}`} onClick={props.handleClose} />;
  }
}

HomeButton.propTypes = {
  active: PropTypes.bool.isRequired,
  invert: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default HomeButton;

import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

const Wifi = ({ invert }) => (
  <div className={`${s.container} ${invert && s.invert}`}>
    <div className={`${s.circle} ${s.large}`} />
    <div className={`${s.circle} ${s.medium}`} />
    <div className={`${s.circle} ${s.small}`} />
  </div>
);

Wifi.propTypes = {
  invert: PropTypes.bool.isRequired,
};

export default Wifi;

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../../../components';
import s from './Track.module.scss';

const Visualizer = ({ on }) => (
  <div className={`${s.overlay} ${on && s.on}`}>
    <div className={`${s.bars} ${on && s.on}`}>
      {Array.from(Array(4).keys()).map((key) => (
        <div key={key} className={`${s.bar} ${s['bar' + (key + 1)]}`} />
      ))}
    </div>
  </div>
);

Visualizer.propTypes = {
  on: PropTypes.bool.isRequired,
};

const Album = ({ image, title, visualizer, playing, shadow }) => (
  <div className={`${s.album} ${shadow && s.shadow}`}>
    {visualizer && <Visualizer on={playing} />}
    <Image alt={title} className={s.image} src={image} webp={image.replace(/\..+$/g, '.webp')} />
  </div>
);

Album.propTypes = {
  image: PropTypes.string.isRequired,
  playing: PropTypes.bool,
  shadow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  visualizer: PropTypes.bool,
};

Album.defaultProps = {
  playing: false,
  shadow: false,
  visualizer: true,
};

const CurrentSong = ({ playing, playSong, stopSong, currentSong, disabled, playNextSong }) => (
  <div className={s.nowPlaying}>
    <Album title={currentSong.title} image={currentSong.image} visualizer={false} shadow />
    <div className={s.info}>
      <h2 className={s.title}>{currentSong.title}</h2>
      <React.Fragment>
        <button className={s.playBtn} onClick={() => (playing ? stopSong() : playSong(currentSong.index))}>
          <div className={`${playing ? s.pause : s.play}`} />
        </button>
        <button className={`${s.nextBtn} ${disabled && s.disabled}`} disabled={disabled} onClick={playNextSong}>
          <div className={s.next} />
          <div className={s.next} />
        </button>
      </React.Fragment>
    </div>
  </div>
);

CurrentSong.propTypes = {
  currentSong: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  playNextSong: PropTypes.func.isRequired,
  playSong: PropTypes.func.isRequired,
  stopSong: PropTypes.func.isRequired,
};

const Song = ({ playSong, image, title, artist, trackIndex, playing, active, disabled, tooltip }) => (
  <button className={s.container} onClick={() => playSong(trackIndex, true)} title={tooltip || ''}>
    <div className={s.album}>
      <Album title={title} image={image} playing={playing} visualizer={!disabled && active} />
    </div>
    <div className={s.info}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.artist}>{artist}</p>
    </div>
  </button>
);

Song.propTypes = {
  active: PropTypes.bool.isRequired,
  artist: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  image: PropTypes.string,
  playing: PropTypes.bool.isRequired,
  playSong: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  trackIndex: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
};

const Track = (props) => (props.currentSong ? <CurrentSong {...props} /> : <Song {...props} />);

export default Track;

import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '../../components';
import { FancyBtn, Track } from './subcomponents';
import s from './music.module.scss';

class Music extends React.Component {
  static propTypes = {
    nowPlaying: PropTypes.shape({
      artist: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    nowPlaying: {
      artist: null,
      image: null,
      index: 0,
    },
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const disabled = !!this.props.nowPlaying.artist === false;

    return (
      <section className={s.container} ref={this.ref}>
        <Title className={s.title} copy="Songs" />
        <div className={s.playBtns}>
          <FancyBtn copy="Play" clickHandler={() => this.props.playSong(0, true)} />
          <FancyBtn copy="Shuffle" clickHandler={this.props.playRandomSong} />
        </div>
        <div className={s.tracks}>
          {this.props.tracks.map((track, i) => (
            <Track
              {...track}
              active={i === this.props.nowPlaying.index}
              disabled={disabled}
              key={track.title}
              playing={this.props.playing}
              playSong={this.props.playSong}
              trackIndex={i}
            />
          ))}
        </div>
        <div className={s.nowPlaying}>
          <Track
            currentSong={this.props.nowPlaying}
            disabled={disabled}
            playing={this.props.playing}
            playNextSong={this.props.playNextSong}
            playSong={this.props.playSong}
            stopSong={this.props.stopSong}
          />
        </div>
      </section>
    );
  }
}

export default Music;

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import * as mailActionCreators from '../../../../redux/modules/mail';
import { formatFormData, validateEmail } from '../../../../helpers/utils';
import DraftEmail from './DraftEmail';
import { updateInvert } from '../../../../redux/modules/infoBar';

class DraftEmailVM extends React.Component {
  static createViewModel() {
    return DraftEmail;
  }

  static propTypes = {
    date: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDetailsScreenActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onToggleDetails: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
    isReply: PropTypes.bool,
    updateSendingEmail: PropTypes.func.isRequired,
    updateSendingEmailError: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isScreenActive: PropTypes.bool.isRequired,
    invert: PropTypes.bool.isRequired,
  };

  static encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.textareaRef = React.createRef();
    this.state = {
      email: true,
      message: true,
      name: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextareaInput = this.handleTextareaInput.bind(this);

    this.Component = DraftEmailVM.createViewModel();
  }

  componentDidUpdate() {
    this.checkHomeButton();
  }

  checkHomeButton() {
    if (!this.props.isActive || !this.props.invert || !this.props.isScreenActive) return;

    this.props.dispatch(updateInvert(false, true));
  }

  handleSubmit({ event, ref }) {
    event.preventDefault();
    this.validateForm(ref.current);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: true,
    });
  }

  handleClose(...rest) {
    this.props.onClose(...rest);

    setTimeout(() => {
      this.textareaRef.current.parentNode.dataset.replicatedValue = '';
      this.setState({
        email: true,
        message: true,
        name: true,
      });
    }, 1000);
  }

  async submitForm(fields, form) {
    if (this.props.isDetailsScreenActive) this.props.onToggleDetails();

    this.props.updateSendingEmail(true);
    this.handleClose(false);

    const { error } = await axios({
      url: '/contact',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: fields,
      paramsSerializer: DraftEmailVM.encode,
    }).catch((error) => ({ error }));

    this.props.updateSendingEmailError(error || null);
    if (!error) form.reset();
  }

  validateForm(form) {
    const newState = {};
    const fields = formatFormData(form);
    let isValid = true;

    Object.keys(fields).forEach((field) => {
      newState[field] = field === 'email' ? validateEmail(fields.email) : fields[field].length >= 3;
      if (isValid && !newState[field]) isValid = false;
    });

    this.setState(newState);
    if (isValid) return this.submitForm(fields, form);
  }

  handleTextareaInput() {
    this.textareaRef.current.parentNode.dataset.replicatedValue = this.textareaRef.current.value;
  }

  render() {
    return (
      <this.Component
        {...{ fields: { ...this.state } }}
        {...this.props}
        formRef={this.formRef}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        isReply={!!this.props.isReply}
        onClose={this.handleClose}
        onTextareaInput={this.handleTextareaInput}
        textareaRef={this.textareaRef}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(mailActionCreators, dispatch),
    dispatch,
  };
}

export default compose(
  connect(
    ({ infobarReducer, iphoneReducer }) => ({
      invert: !!(infobarReducer.get('invert') || infobarReducer.get('isHomeButtonDark')),
      isScreenActive: iphoneReducer.get('view') === 'mail',
    }),
    mapDispatchToProps,
  ),
)(DraftEmailVM);

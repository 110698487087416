import React from 'react';
import PropTypes from 'prop-types';
import { Wifi, Signal, Battery } from '../../components';
import s from './styles.module.scss';

const InfoBar = ({ invert, time, hidden }) => (
  <section className={`${s.container} ${!hidden && s.display}`}>
    <div className={`${s.time} ${invert && s.invert}`}>{time}</div>
    <div className={s.info}>
      <Signal invert={invert} />
      <Wifi invert={invert} />
      <Battery invert={invert} />
    </div>
  </section>
);

InfoBar.propTypes = {
  time: PropTypes.string.isRequired,
  invert: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default InfoBar;

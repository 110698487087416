import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../components';
import s from './styles.module.scss';

const AppleLogo = ({ loading }) => (
  <section className={`${s.container} ${loading && s.animate}`}>
    <Image className={s.logo} src="/images/loading.png" webp="/images/loading.webp" alt="apple logo" />
  </section>
);

AppleLogo.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default AppleLogo;

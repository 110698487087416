import React from 'react';
import PropTypes from 'prop-types';
import s from './Time.module.scss';

const InfoBar = ({ time, invert }) => <div className={`${s.time} ${invert && s.invert}`}>{time}</div>;

InfoBar.propTypes = {
  time: PropTypes.string.isRequired,
  invert: PropTypes.bool.isRequired,
};

export default InfoBar;

import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

function shorten(str) {
  return str.replace(/[a-z]+/i, (match) => match.slice(0, 3));
}

const BodyMessage = () => (
  <React.Fragment>
    <p className={s.message}>Hey!</p>
    <p className={s.message}>Thanks for checking out my site! How do you like it? Reply back and let me know!</p>
    <p className={s.message}>Best,</p>
    <p className={s.message}>Justin Woodward</p>
  </React.Fragment>
);

function DraftEmail(props) {
  const { fields, handleChange, formRef, isReply } = props;

  return (
    <form
      name="contact"
      className={`${s.container} ${props.isActive && s.active}`}
      onSubmit={(e) => props.handleSubmit({ event: e, ref: formRef })}
      ref={formRef}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className={s.options}>
        <button className={s.actionBtn} type="button" onClick={() => props.onClose(false, formRef.current)}>
          Cancel
        </button>
        <p className={s.title}>{isReply ? 'Re: Hey, checking in!' : 'New Message'}</p>
        <button className={`${s.actionBtn} ${s.bold}`}>Send</button>
      </div>
      <p className={s.headerBlock}>
        <span className={s.lighten}>To:</span>
        <span className={s.to}>Justin Woodward</span>
      </p>
      <label htmlFor="name" className={`${s.headerBlock} ${!fields.name && s.error}`}>
        <span className={`${s.lighten} ${s.label}`}>Name:</span>
        <input name="name" placeholder="John Doe" className={s.input} onChange={!fields.name ? handleChange : null} />
      </label>
      <label htmlFor="email" className={`${s.headerBlock} ${!fields.email && s.error}`}>
        <span className={`${s.lighten} ${s.label}`}>From:</span>
        <input
          type="email"
          name="email"
          placeholder="name@domain.com"
          className={s.input}
          onChange={!fields.email ? handleChange : null}
        />
      </label>
      <div className={s.content}>
        <div className={`${s.form} ${!fields.message && s.error}`}>
          <textarea
            name="message"
            className={s.textarea}
            placeholder={isReply ? 'Enter reply...' : 'Enter message...'}
            onChange={!fields.message ? handleChange : null}
            onInput={props.onTextareaInput}
            ref={props.textareaRef}
          />
        </div>
        {!isReply && <p className={s.emailSignature}>Sent from my iPhone</p>}
        {isReply && (
          <div className={s.footer}>
            {`On ${shorten(props.date)}, Justin Woodward <justinwoodward.com> wrote:`}
            <BodyMessage />
          </div>
        )}
      </div>
    </form>
  );
}

DraftEmail.propTypes = {
  isActive: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onTextareaInput: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formRef: PropTypes.object.isRequired,
  textareaRef: PropTypes.object.isRequired,
  isReply: PropTypes.bool.isRequired,
};

export default DraftEmail;

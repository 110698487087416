import React from 'react';
import PropTypes from 'prop-types';

function Image(props) {
  return (
    <picture>
      <source srcSet={props.webp} type="image/webp" />
      <source srcSet={props.src} type="image/jpeg" />
      <img rel="preload" src={props.src} alt={props.alt} className={props.className} title={props.title} />
    </picture>
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.string,
  title: PropTypes.string,
  webp: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
  className: null,
  style: null,
  title: null,
  webp: null,
};

export default Image;

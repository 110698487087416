import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withIphone } from '../../hocs';
import { updateScreenPosition, updatePower } from '../../redux/modules/Iphone';
import config from '../../config/applications';
import IphoneContainer from './IphoneContainer';

class IphoneVM extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.Component = this.createViewModel();
    this.handleScreenPosition = this.handleScreenPosition.bind(this);
    this.updatePowerStatus = this.updatePowerStatus.bind(this);
  }

  createViewModel() {
    return IphoneContainer;
  }

  handleScreenPosition(screenPosition) {
    this.props.dispatch(updateScreenPosition(screenPosition));
  }

  updatePowerStatus() {
    this.props.dispatch(updatePower(true));
  }

  render() {
    return (
      <this.Component
        {...this.props}
        dock={config.dock}
        applications={config.applications}
        handleScreenPosition={this.handleScreenPosition}
        updatePowerStatus={this.updatePowerStatus}
      />
    );
  }
}

export default withIphone(connect()(IphoneVM));

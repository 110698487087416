import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import HackerNews from './HackerNews';

export default class HackerNewsVM extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  constructor() {
    super();
    this.Component = this.createViewModel();
  }

  mapStateToProps() {
    return {};
  }

  createViewModel() {
    return React.memo(HackerNews);
  }

  render() {
    return <this.Component {...this.props} />;
  }
}

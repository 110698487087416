import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTime } from '../../helpers/utils';
import * as infobarActionCreators from '../../redux/modules/infoBar';
import InfoBar from './InfoBar';

export default class InfoBarVM extends React.Component {
  constructor() {
    super();
    this.state = {
      time: '',
    };

    this.interval = null;
    this.handleTime = this.handleTime.bind(this);
    this.Component = this.createViewModel();
  }

  componentDidMount() {
    this.interval = setInterval(this.handleTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  mapStateToProps = ({ infobarReducer }) => ({
    batteryLevel: infobarReducer.getIn(['battery', 'level']),
    invert: infobarReducer.get('invert'),
  });

  mapDispatchToProps(dispatch) {
    return bindActionCreators(infobarActionCreators, dispatch);
  }

  handleTime() {
    const time = getTime();

    if (time !== this.state.time) {
      this.setState({ time: getTime() });
    }
  }

  createViewModel() {
    return connect(this.mapStateToProps, this.mapDispatchToProps)(React.memo(InfoBar));
  }

  render() {
    return <this.Component {...this.props} {...this.state} />;
  }
}

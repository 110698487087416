import React from 'react';
import PropTypes from 'prop-types';
import s from './LoadingBar.module.scss';

class LoadingBar extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      done: false,
      loading: false,
    };
  }

  async shouldComponentUpdate({ loading: nextLoading }) {
    const { loading } = this.props;

    if (nextLoading && !loading) {
      await this.handleLoading();
      return true;
    }

    if (loading && !nextLoading) {
      await this.handleDone();
      return true;
    }

    return nextLoading !== loading;
  }

  async handleDone() {
    await new Promise((resolve) => this.setState({ done: true }, resolve));

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          {
            done: false,
            loading: false,
          },
          resolve,
        );
      }, 250);
    });
  }

  handleLoading() {
    return new Promise((resolve) => this.setState({ loading: true }, resolve));
  }

  render() {
    return (
      <div className={s.container}>
        <div className={`${s.bar} ${this.state.loading && s.loading} ${this.state.done && s.done}`} />
      </div>
    );
  }
}

export default LoadingBar;

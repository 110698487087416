import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Button = ({ bg, content, handler, isActive }) => (
  <button
    className={`${styles.btn} ${styles[bg]} ${content === '0' && styles.zero} ${isActive && styles.active}`}
    onClick={handler}
  >
    {content}
  </button>
);

Button.propTypes = {
  bg: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handler: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, HomeButton } from '../../components';
import s from './Application.module.scss';

function AppIcon({ title, imageURL, url, readOnly: ReadOnly, isActive, position, loading }) {
  const positionConfig = position;
  delete positionConfig.homeBtn;

  const IconComp = () => (
    <Icon title={title} imageURL={imageURL} isActive={isActive} positionConfig={positionConfig} loading={loading}>
      {ReadOnly && <ReadOnly />}
    </Icon>
  );

  if (!url) {
    return <IconComp />;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <IconComp />
    </a>
  );
}

AppIcon.propTypes = {
  imageURL: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  position: PropTypes.object,
  readOnly: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
};

class App extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    app: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    notifications: PropTypes.number,
    readOnly: PropTypes.any,
    screenPosition: PropTypes.any,
    title: PropTypes.string,
    url: PropTypes.string,
  };

  static defaultProps = {
    notifications: 0,
    loading: false,
  };

  constructor() {
    super();
    this.myRef = React.createRef();
  }

  render() {
    const ref = this.myRef.current ? this.myRef.current.getBoundingClientRect() : null;
    const position = {};

    if (this.props.screenPosition) {
      const sc = this.props.screenPosition;

      position.top = `-${ref.top - sc.top}px`;
      position.left = `-${ref.left - sc.left}px`;
      position.width = `${sc.width}px`;
      position.minHeight = `${sc.height}px`;
      position.homeBtn = `${sc.width * 0.8}px`;
    }
    const { app: MobileApp } = this.props;

    return (
      <React.Fragment>
        <section className={s.container} ref={this.myRef}>
          <div className={`${s.notification} ${this.props.notifications < 1 && s.notificationHide}`}>
            {this.props.notifications}
          </div>
          <AppIcon
            imageURL={this.props.icon}
            isActive={this.props.active}
            loading={this.props.loading}
            position={this.props.active ? position : {}}
            readOnly={this.props.readOnly}
            title={this.props.title}
            url={this.props.url}
          />
          {MobileApp && (
            <React.Fragment>
              <button
                className={`${s.appContainer} ${!this.props.active && s.inactive} ${s.hidden}`}
                onClick={this.props.handleOpen}
              />
              <div
                className={`${s.appContainer} ${!this.props.active && s.hide}`}
                style={this.props.active ? position : { width: position.width, minHeight: position.minHeight }}
              >
                <MobileApp />
              </div>
            </React.Fragment>
          )}
        </section>
        <HomeButton active={this.props.active} handleClose={this.props.handleClose} />
      </React.Fragment>
    );
  }
}

export default App;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { withRouter } from 'react-router-dom';

import * as calculatorActionCreators from '../../redux/modules/calculator';
import { Calculator } from '../../components';

class CalculatorContainer extends Component {
  static propTypes = {
    display: PropTypes.object.isRequired,
    buttons: PropTypes.instanceOf(List),
    handleOperation: PropTypes.func.isRequired,
    active: PropTypes.string,
  };

  handleOperations(type) {
    this.props.handleOperation(type);
  }

  render() {
    return (
      <Calculator
        active={this.props.active}
        display={this.props.display}
        buttons={this.props.buttons}
        handleOperations={(type) => this.handleOperations(type)}
      />
    );
  }
}

const mapStateToProps = ({ calculator }) => ({
  display: calculator.get('display'),
  buttons: calculator.get('buttons'),
  active: calculator.getIn(['operator', 'type']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(calculatorActionCreators, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CalculatorContainer),
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Display = ({ display }) => {
  let view = display.get('view');
  let fontSize = 80;
  const viewLength = view.toString().length;

  if (viewLength === 7) {
    fontSize = 72;
  } else if (viewLength >= 8) {
    fontSize = 62;

    if (viewLength > 8) {
      view = view.toString().slice(0, 8);
    }
  }

  return (
    <div className={styles.container} style={{ fontSize: `${fontSize}px` }}>
      {view}
    </div>
  );
};

Display.propTypes = {
  display: PropTypes.object.isRequired,
};

export default Display;

import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { MainContainer, IphoneContainer, ScrollToTopOnMount } from '../containers';

const getRoutes = () => (
  <Router>
    <ScrollToTopOnMount>
      <MainContainer>
        <Switch>
          <Route exact path="/" component={IphoneContainer} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </MainContainer>
    </ScrollToTopOnMount>
  </Router>
);

export default getRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as infobarActionCreators from '../../redux/modules/infoBar';
import Battery from './Battery';

class BatteryVM extends React.Component {
  static propTypes = {
    batteryLevel: PropTypes.number.isRequired,
    charging: PropTypes.bool.isRequired,
    invert: PropTypes.bool.isRequired,
    updateBattery: PropTypes.func.isRequired,
  };

  static mapStateToProps({ infobarReducer }) {
    return {
      batteryLevel: infobarReducer.getIn(['battery', 'level']),
      charging: infobarReducer.getIn(['battery', 'charging']),
      invert: infobarReducer.get('invert'),
    };
  }

  static mapDispatchToProps(dispatch) {
    return bindActionCreators(infobarActionCreators, dispatch);
  }

  constructor(props) {
    super(props);

    this.state = {
      timer: null,
    };
    this.Component = this.createViewModel();
    this.updateBattery = this.updateBattery.bind(this);
    // this.batteryStatusAPI = this.batteryStatusAPI.bind(this);
    // this.handleBatteryChange = this.handleBatteryChange.bind(this);
  }

  componentDidMount() {
    // try {
    //   this.battery = await this.batteryStatusAPI();
    // } catch (e) {
    //   this.handleUpdateBattery();
    // }

    this.handleUpdateBattery();
  }

  shouldComponentUpdate(nextProps) {
    const { batteryLevel, charging, invert } = this.props;
    const batteryLvlChanged = batteryLevel !== nextProps.batteryLevel;
    const chargingChanged = charging !== nextProps.charging;
    const invertChanged = invert !== nextProps.invert;

    // if (!this.battery && !charging && nextProps.charging) {
    if (!charging && nextProps.charging) {
      this.handleUpdateBattery();
    }

    return batteryLvlChanged || chargingChanged || invertChanged;
  }

  // componentWillUnmount() {
  //   this.battery.removeEventListener('levelchange', this.handleBatteryChange);
  //   this.battery.removeEventListener('chargingchange', this.handleBatteryChange);
  // }

  // handleBatteryChange({ target: battery }) {
  //   this.props.updateBattery(battery.level * 100, battery.charging);
  // }

  // async batteryStatusAPI() {
  //   const battery = await navigator.getBattery();

  //   battery.addEventListener('levelchange', this.handleBatteryChange);
  //   battery.addEventListener('chargingchange', this.handleBatteryChange);

  //   this.props.updateBattery(battery.level * 100, battery.charging);

  //   return battery;
  // }

  handleUpdateBattery() {
    clearInterval(this.state.timer);

    this.setState({
      timer: setInterval(this.updateBattery, 3000),
    });
  }

  updateBattery() {
    const { charging } = this.props;

    this.props.updateBattery(this.props.batteryLevel + (charging ? 1 : -1), charging);
  }

  createViewModel() {
    return React.memo(Battery);
  }

  render() {
    return (
      <this.Component
        batteryLevel={this.props.batteryLevel}
        charging={this.props.charging}
        invert={this.props.invert}
      />
    );
  }
}

export default connect(BatteryVM.mapStateToProps, BatteryVM.mapDispatchToProps)(BatteryVM);

import 'react-app-polyfill/ie11';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './sharedStyles/theme.scss';
import getRoutes from './config/routes';
import * as serviceWorker from './serviceWorker';
import client, { store } from './client';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>{getRoutes(store)}</Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

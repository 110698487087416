import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../components';
import s from './styles.module.scss';

const Icon = ({ children, imageURL, title, isActive, loading }) => {
  return (
    <React.Fragment>
      <div className={`${s.icon} ${isActive && s.hidden}`}>
        {imageURL && (
          <Image
            alt={`${title} icon`}
            className={s.image}
            src={imageURL}
            title={title}
            webp={`${imageURL.split('.')[0]}.webp`}
          />
        )}
        {children}
        {loading && (
          <div className={s.loading}>
            <div className={s.pie} />
          </div>
        )}
      </div>
      {title && <p className={s.name}>{title}</p>}
    </React.Fragment>
  );
};

Icon.propTypes = {
  children: PropTypes.any,
  imageURL: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  positionConfig: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default Icon;

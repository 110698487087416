import React from 'react';
import PropTypes from 'prop-types';

const NewTabLink = ({ component: Component, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <Component />
  </a>
);

NewTabLink.propTypes = {
  link: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
};

export default NewTabLink;

import React from 'react';
import Calendar from './Calendar';

export default class CalendarVM extends React.Component {
  constructor() {
    super();
    this.Component = this.createViewModel();
  }

  mapStateToProps() {
    return {
      ...this.props,
      iconBody: () => <Calendar {...this.mapStateToProps()} />,
    };
  }

  createViewModel() {
    return React.memo(Calendar);
  }

  render() {
    return <this.Component />;
  }
}

import { fromJS } from 'immutable';
import { ButtonsStore } from '../../stores';

const UPDATE_DISPLAY = 'UPDATE_DISPLAY';
const HANDLE_ALL_CLEAR = 'HANDLE_ALL_CLEAR';
const HANDLE_SET_OPERATOR = 'HANDLE_SET_OPERATOR';
const REMOVE_ACTIVE_OPERATOR = 'REMOVE_ACTIVE_OPERATOR';
const DISPLAY_TOTAL = 'DISPLAY_TOTAL';

const updateDisplay = (value, result) => ({
  type: UPDATE_DISPLAY,
  value,
  result,
});

const handleSetOperator = (operator) => ({
  type: HANDLE_SET_OPERATOR,
  operator,
});

const handleAllClear = () => ({
  type: HANDLE_ALL_CLEAR,
});

const removeActiveOperator = () => ({
  type: REMOVE_ACTIVE_OPERATOR,
});

const appendNumber = (currentVal, val) => (dispatch) => {
  const result = parseFloat(currentVal.toString() + val, 10);

  dispatch(updateDisplay(result));
};

const calculate = (operator, currentVal, val) => (dispatch) => {
  const result = calculations[operator](currentVal, val);

  dispatch(removeActiveOperator());
  dispatch(updateDisplay(val, result));
};

const calculations = {
  add: (num1, num2) => num1 + num2,
  divide: (num1, num2) => num1 / num2,
  multiply: (num1, num2) => num1 * num2,
  subtract: (num1, num2) => num1 - num2,
  plusNegative: (num) => num * -1,
  percent: (num) => num * 0.01,
};

export const handleOperation = (type) => (dispatch, getState) => {
  const state = getState().calculator;
  const currentVal = state.getIn(['display', 'view']);
  const val = type.get('value');
  const group = type.get('group');

  if (group === 'clear') {
    return dispatch(handleAllClear());
  } else if (group === 'total') {
    return dispatch(
      updateDisplay(
        calculations[state.getIn(['numbers', 'operator'])](
          state.getIn(['numbers', 'previous']),
          state.getIn(['numbers', 'current']),
        ),
      ),
    );
  } else if (val === 'plusNegative') {
    return dispatch(updateDisplay(calculations.plusNegative(currentVal)));
  } else if (val === 'percent') {
    return dispatch(updateDisplay(calculations.percent(currentVal)));
  } else if (state.getIn(['operator', 'active'])) {
    return dispatch(calculate(state.getIn(['operator', 'type']), currentVal, val));
  } else if (group === 'number') {
    return dispatch(appendNumber(currentVal, val));
  } else if (group === 'decimal' && currentVal.toString().indexOf('.') === -1) {
    return dispatch(updateDisplay(currentVal + '.'));
  } else if (group === 'operator' && !state.getIn(['operator', 'active'])) {
    return dispatch(handleSetOperator(val));
  }
};

const initialState = fromJS({
  numbers: {
    previous: 0,
    operator: null,
    current: 0,
    output: 0,
  },
  display: {
    view: '0',
  },
  buttons: ButtonsStore.buttons,
  operator: {
    active: false,
    type: null,
  },
});

const calculator = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DISPLAY:
      return state.merge({
        display: state.get('display').set('view', action.value),
        numbers: state
          .get('numbers')
          .set('current', parseFloat(action.value, 10))
          .set('output', parseFloat(action.result || action.value), 10),
      });
    case HANDLE_ALL_CLEAR:
      return initialState;
    case HANDLE_SET_OPERATOR:
      return state.merge({
        operator: state
          .get('operator')
          .set('active', true)
          .set('type', action.operator),
        numbers: state
          .get('numbers')
          .set('previous', state.getIn(['display', 'view']))
          .set('operator', action.operator),
      });
    case REMOVE_ACTIVE_OPERATOR:
      return state.merge({
        operator: initialState.get('operator'),
      });
    case DISPLAY_TOTAL:
      return state.merge({
        display: state.get('display').set('view', state.getIn(['display', 'value'])),
      });
    default:
      return state;
  }
};

export default calculator;

import React from 'react';
import PropTypes from 'prop-types';
import { getDay } from '../../helpers/utils';
import s from './styles.module.scss';

function Calendar() {
  const date = new Date();

  return (
    <div className={s.container}>
      <span className={s.day}>{getDay(date.getDay())}</span>
      <span className={s.number}>{date.getDate()}</span>
    </div>
  );
}

Calendar.propTypes = {
  title: PropTypes.string,
};

export default Calendar;

import React from 'react';
import PropTypes from 'prop-types';
import s from './FancyBtn.module.scss';

const FancyBtn = ({ copy, clickHandler }) => (
  <button className={s.btn} onClick={clickHandler}>
    {copy}
  </button>
);

FancyBtn.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  copy: PropTypes.string.isRequired,
};

export default FancyBtn;

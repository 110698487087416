import React from 'react';
import s from './styles.module.scss';

class Bio extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }

  render() {
    return (
      <section className={s.container} ref={this.ref}>
        HackerNews
      </section>
    );
  }
}

export default Bio;

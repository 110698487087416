import React from 'react';
import PropTypes from 'prop-types';
import { withNewTabLink } from '../../hocs';
import Icon from './Icon';

export default class CalendarVM extends React.Component {
  static defaultProps = {
    link: null,
  };

  static propTypes = {
    link: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.Component = this.createViewModel();
  }

  mapStateToProps() {
    const { props } = this;

    return {
      ...props,
      title: props.loading ? 'Loading...' : props.title,
    };
  }

  createViewModel() {
    const component = () => Icon;

    if (this.props.link) {
      return withNewTabLink({
        component,
        link: this.props.link,
      });
    }

    return React.memo(component());
  }

  render() {
    return <this.Component {...this.mapStateToProps()} />;
  }
}

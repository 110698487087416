import React from 'react';
import PropTypes from 'prop-types';
import { throttleOrDie } from '../../helpers/utils';
import { AppleLogo, InfoBar, Application, Image } from '../../components';
import s from './styles.module.scss';

class Iphone extends React.Component {
  static propTypes = {
    applications: PropTypes.any,
    dock: PropTypes.any,
    handleScreenPosition: PropTypes.func.isRequired,
    hideBackground: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    poweredOn: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.timer = null;
    this.screenRef = React.createRef();
    this.setScreenPosition = this.setScreenPosition.bind(this);
  }

  componentDidMount() {
    if (this.screenRef.current) {
      this.setScreenPosition();

      window.addEventListener('resize', () => {
        this.timer = throttleOrDie(this.timer, this.setScreenPosition, 250);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading && this.props.poweredOn) {
      this.props.updatePowerStatus();
    }
  }

  setScreenPosition() {
    this.props.handleScreenPosition(this.screenRef.current.getBoundingClientRect());
  }

  render() {
    const { hideBackground, loading, poweredOn, applications, dock } = this.props;

    return (
      <div className={`${s.screen} ${hideBackground && s.off}`} ref={this.screenRef}>
        <Image
          alt="phone background image"
          className={`${s.background} ${hideBackground && s.off}`}
          src="/images/home-screen.jpg"
          webp="/images/home-screen.webp"
        />
        {!poweredOn && <AppleLogo loading={loading} />}
        <InfoBar hidden={hideBackground} />
        <div className={`${s.screenContainer} ${hideBackground && s.hide}`}>
          {applications.map((k) => (
            <Application key={k.title} {...k} />
          ))}
        </div>
        <div className={`${s.dock} ${hideBackground && s.hide}`}>
          {dock.map((k) => (
            <Application key={k.url} {...k} />
          ))}
        </div>
      </div>
    );
  }
}

export default Iphone;

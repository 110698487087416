import React from 'react';
import PropTypes from 'prop-types';
import { Charger } from '../../components';
import s from './styles.module.scss';

class Iphone extends React.Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.iphoneRef = React.createRef();
    this.state = {
      appActive: false,
      chargerDistance: null,
      displayArrows: false,
      hideBackground: true,
      loading: false,
      poweredOn: false,
    };

    this.powerOn = this.powerOn.bind(this);
    this.updateAppState = this.updateAppState.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      const chargerDistance = this.getChargerDistance();

      if (this.state.loading || this.state.poweredOn) return this.setState({ chargerDistance });

      this.setState({
        chargerDistance,
        displayArrows: true,
      });
    }, 5000);
  }

  getChargerDistance() {
    if (!this.iphoneRef.current) return null;

    const { bottom } = this.iphoneRef.current.getBoundingClientRect();

    return (window.innerHeight - bottom + 28) * -1;
  }

  displayLogo() {
    this.setState({
      displayArrows: false,
      loading: true,
    });

    setTimeout(() => this.setState({ hideBackground: false }), 1500);
    setTimeout(() => this.setState({ poweredOn: true, loading: false }), 3010);
  }

  powerOn() {
    if (!this.state.poweredOn) {
      this.displayLogo();
    }
  }

  updateAppState(bool) {
    this.setState({ appActive: bool });
  }

  render() {
    const { component: Child } = this.props;

    return (
      <React.Fragment>
        <div className={s.container} ref={this.iphoneRef}>
          <button className={s.powerBtn} onClick={this.powerOn} aria-label="power button">
            {this.state.displayArrows && (
              <React.Fragment>
                <div className={s.helperArrows} />
                <div className={s.helperArrows} />
                <div className={s.helperArrows} />
              </React.Fragment>
            )}
          </button>
          <div className={s.innerContainer}>
            <Child {...this.state} />
            <div className={s.header}>
              <div className={s.sensor} />
            </div>
          </div>
        </div>
        <Charger distance={this.state.chargerDistance} />
      </React.Fragment>
    );
  }
}

export default function withIphone(wrappedComponent) {
  return (props) => <Iphone {...props} component={wrappedComponent} />;
}

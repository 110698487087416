import { fromJS } from 'immutable';

const TOGGLE_CHARGING = 'TOGGLE_CHARGING';
const UPDATE_BATTERY = 'UPDATE_BATTERY';
const UPDATE_INVERT = 'UPDATE_INVERT';

export const toggleCharging = () => ({
  type: TOGGLE_CHARGING,
});

export const updateBattery = (value, charging) => {
  const config = {
    type: UPDATE_BATTERY,
    charging,
    value,
  };

  if (value < 1) {
    config.charging = true;
    config.value = 1;
  }

  if (value > 99) {
    config.charging = false;
    config.value = 100;
  }

  return config;
};

export const updateInvert = (bool = true, isHomeButtonDark) => ({
  type: UPDATE_INVERT,
  bool,
  isHomeButtonDark,
});

const initialState = fromJS({
  battery: {
    charging: false,
    level: 100,
  },
  invert: false,
  isHomeButtonDark: false,
});

const infobar = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CHARGING:
      return state.merge({
        battery: state.get('battery').set('charging', !state.getIn(['battery', 'charging'])),
      });
    case UPDATE_BATTERY:
      return state.merge({
        battery: state
          .get('battery')
          .set('level', action.value)
          .set('charging', action.charging),
      });
    case UPDATE_INVERT:
      return state.merge({
        invert: action.bool,
        isHomeButtonDark:
          typeof action.isHomeButtonDark === 'boolean' ? action.isHomeButtonDark : state.isHomeButtonDark,
      });
    default:
      return state;
  }
};

export default infobar;

export const isProd = process.env.NODE_ENV === 'production' // eslint-disable-line

function formatHours(hours) {
  const formattedHours = hours > 12 ? hours % 12 : hours;
  return formattedHours < 1 ? 12 : formattedHours;
}

function formatMinutes(minutes) {
  return minutes < 10 ? `0${minutes}` : minutes;
}

function beforeAfterNoon(hours) {
  return hours > 11 ? 'PM' : 'AM';
}

export function getTime(noonTime, date) {
  const d = date || new Date();
  const minutes = formatMinutes(d.getMinutes());
  const hours = formatHours(d.getHours());
  const amPM = noonTime ? beforeAfterNoon(d.getHours()) : '';

  return `${hours}:${minutes} ${amPM}`;
}

export function getDay(index) {
  const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return daysOfTheWeek[index];
}

export function getMonth(index) {
  const monthsOfTheYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthsOfTheYear[index];
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(color) {
  const [r, g, b] = color
    .match(/[^\D+].+$/)[0]
    .split(',')
    .map((k) => parseInt(k, 10));

  return componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getContrast(element) {
  const color = rgbToHex(getComputedStyle(element)['background-color']);
  return parseInt(color, 16) > 0xffffff / 2;
}

export function formatFormData(form) {
  const formObj = {};
  const re = /\d{1,2}\/\d{1,2}\/\d{2,4}/;

  Array.from(new FormData(form)).forEach(([k1, k2]) => {
    formObj[k1] = k2.match(re) ? new Date(k2).toISOString() : k2;
    // formObj[k1] = k2
  });

  return formObj;
}

export function validateEmail(value) {
  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return re.test(value);
}

export function throttleOrDie(timer, fn, delay) {
  clearTimeout(timer);

  return setTimeout(fn, delay);
}

import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import HomeButton from './HomeButton';

export default compose(
  connect(({ infobarReducer }) => ({
    invert: infobarReducer.get('invert') || !!infobarReducer.get('isHomeButtonDark'),
  })),
  React.memo,
)(HomeButton);

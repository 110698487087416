import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../components';
import { Email, LoadingBar, DraftEmail } from './subcomponents';
import s from './styles.module.scss';

const FlagBtn = ({ display }) => (
  <div className={s.flag}>
    <button className={`${s.filterBtn} ${display ? s.hide : ''}`} disabled>
      <Image src="/images/filter.png" webp="/images/filter.webp" alt="filter" className={s.filterImage} />
    </button>
    <button className={`${s.flagBtn} ${!display ? s.hide : ''}`} disabled>
      <Image src="/images/flag.png" webp="/images/flag.webp" alt="flag" className={s.flagImage} />
    </button>
  </div>
);

FlagBtn.propTypes = {
  display: PropTypes.bool.isRequired,
};

const CreateBtn = ({ handleClick }) => (
  <div className={s.create}>
    <button className={s.createBtn} onClick={() => handleClick(true)}>
      <Image src="/images/compose.png" webp="/images/compose.webp" alt="compose" className={s.createImage} />
    </button>
  </div>
);

CreateBtn.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

const ReplyBtn = ({ display, handleClick }) => (
  <div className={`${s.reply} ${!display ? s.hide : ''}`}>
    <button className={s.replyBtn} onClick={() => handleClick(true)}>
      <Image src="/images/reply.png" webp="/images/reply.webp" alt="reply" className={s.replyImage} />
    </button>
  </div>
);

ReplyBtn.propTypes = {
  display: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const FileBtn = ({ display }) => (
  <div className={`${s.file} ${!display ? s.hide : ''}`}>
    <button className={s.fileBtn} disabled>
      <Image src="/images/organize.png" webp="/images/organize.webp" alt="organize" className={s.fileImage} />
    </button>
  </div>
);

FileBtn.propTypes = {
  display: PropTypes.bool.isRequired,
};

const TrashBtn = ({ display }) => (
  <div className={s.trash}>
    <button className={`${s.trashBtn} ${!display ? s.hide : ''}`} disabled>
      <Image src="/images/trash.png" webp="/images/trash.webp" alt="trash" className={s.trashImage} />
    </button>
  </div>
);

TrashBtn.propTypes = {
  display: PropTypes.bool.isRequired,
};

// <button className={s.mailboxes} disabled>
//   MailBoxes
// </button>

const Mail = ({
  active,
  date,
  displayUnreadMessage,
  handleBackArrowClick,
  handleToggleDetails,
  isSending,
  isReplyScreenVisible,
  isComposeScreenVisible,
  status,
  time,
  toggleReplyScreen,
  toggleComposeScreen,
  isScaledHeader,
}) => {
  return (
    <section className={`${s.container} ${active && s.active}`}>
      <div className={s.black} />
      <div className={`${s.wrapper} ${isScaledHeader && s.scaled}`}>
        <header className={s.header}>
          <div className={s.headerBtns}>
            <button className={`${s.backArrow} ${!active && s.hide}`} onClick={handleBackArrowClick} />
          </div>
          <div className={s.navigation}>
            <button className={s.backBtn} disabled={!active} onClick={active ? handleToggleDetails : null}>
              <h1 className={s.title}>Inbox</h1>
            </button>
          </div>
        </header>
        <div className={s.body}>
          <Email
            body="Hey! Thanks for checking out my site! How do you like it? Reply back and let me know! Best,..."
            date={date}
            displayDetails={active}
            handleToggleDetails={handleToggleDetails}
            sender="Justin Woodward"
            time={time}
            title="Hey, checking in!"
          />
        </div>
        <footer className={s.footer}>
          <LoadingBar loading={isSending} />
          <div className={s.options}>
            <FlagBtn display={active} />
            <FileBtn display={active} />
            <TrashBtn display={active} />
            <ReplyBtn display={active} handleClick={toggleReplyScreen} date={date} />
            <CreateBtn handleClick={toggleComposeScreen} />
          </div>
          <p className={`${s.status} ${displayUnreadMessage && s.statusUnread} ${active && s.hide}`}>{status}</p>
          {displayUnreadMessage && <p className={`${s.unread} ${active && s.hide}`}>1 Unread</p>}
        </footer>
        <div className={s.overlay} />
      </div>
      <DraftEmail
        date={date}
        isActive={isReplyScreenVisible}
        isDetailsScreenActive={active}
        isReply
        onClose={toggleReplyScreen}
        onToggleDetails={handleToggleDetails}
      />
      <DraftEmail
        date={date}
        isActive={isComposeScreenVisible}
        isDetailsScreenActive={active}
        onClose={toggleComposeScreen}
        onToggleDetails={handleToggleDetails}
      />
    </section>
  );
};

Mail.propTypes = {
  active: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  displayUnreadMessage: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleBackArrowClick: PropTypes.func.isRequired,
  handleToggleDetails: PropTypes.func.isRequired,
  isComposeScreenVisible: PropTypes.bool.isRequired,
  isReplyScreenVisible: PropTypes.bool.isRequired,
  isSending: PropTypes.bool.isRequired,
  toggleReplyScreen: PropTypes.func.isRequired,
  toggleComposeScreen: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string,
  isScaledHeader: PropTypes.bool.isRequired,
};

export default Mail;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInvert } from '../../redux/modules/infoBar';
import { toggleAppState } from '../../redux/modules/Iphone';
import { getContrast } from '../../helpers/utils';
import Application from './Application';

class ApplicationVM extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.ref = React.createRef();
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.mapStateToProps = this.mapStateToProps.bind(this);
    this.Component = this.createViewModel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.active !== this.state.active;
  }

  mapStateToProps(state) {
    const reducer = this.props.redux; // eslint-disable-line

    return {
      handleClose: this.handleClose,
      handleOpen: this.handleOpen,
      notifications: reducer ? state[reducer].get('notifications') : 0,
      ref: this.ref,
      screenPosition: state.iphoneReducer.get('screenPosition'),
    };
  }

  handleOpen() {
    const color = getContrast(this.ref.current.myRef.current.querySelector('section'));
    this.props.dispatch(updateInvert(color));
    this.props.dispatch(
      toggleAppState({
        active: true,
        component: this.ref.current.props.componentName,
      }),
    );
    this.setState({ active: true });
  }

  handleClose() {
    this.setState({ active: false });
    this.props.dispatch(updateInvert(false));
    this.props.dispatch(toggleAppState({ active: false }));
  }

  createViewModel() {
    return connect(this.mapStateToProps)(React.memo(Application));
  }

  render() {
    return <this.Component {...this.props} {...this.state} />;
  }
}

export default connect()(ApplicationVM);

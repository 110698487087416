import React from 'react';
import PropTypes from 'prop-types';
import { toggleCharging } from '../../redux/modules/infoBar';
import s from './Charger.module.scss';

const Charger = ({ charging, dispatch, distance }) => {
  const position = `${charging ? distance : distance + 60}px`;

  return (
    <button
      aria-label="toggle charger cord"
      className={s.container}
      onClick={() => dispatch(toggleCharging())}
      style={{ transform: `translateY(${position})` }}
    >
      <div className={s.plug}>
        <div className={s.tip}>
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
          <div className={s.gold} />
        </div>
      </div>
      <div className={s.head} />
      <div className={s.neck} />
      <div className={s.cord} />
    </button>
  );
};

Charger.propTypes = {
  charging: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  distance: PropTypes.number,
};

Charger.defaultProps = {
  distance: null,
};

export default Charger;

import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

const Battery = ({ invert, batteryLevel, charging }) => {
  const colored = batteryLevel < 20 ? s.lowBattery : '';

  return (
    <div className={`${s.container} ${invert && s.inverted} ${charging && s.charging}`}>
      <div className={`${s.level} ${colored}`} style={{ width: `${batteryLevel}%` }} />
    </div>
  );
};

Battery.propTypes = {
  batteryLevel: PropTypes.number.isRequired,
  charging: PropTypes.bool.isRequired,
  invert: PropTypes.bool.isRequired,
};

export default Battery;

import React from 'react';
import { connect } from 'react-redux';
import { getMonth, getTime } from '../../helpers/utils';
import { updateInvert } from '../../redux/modules/infoBar';
import { updateNotifications } from '../../redux/modules/mail';
import Mail from './Mail';

function getDate(d) {
  return `${getMonth(d.getMonth())} ${d.getDate()}, ${d.getFullYear()}`;
}

class MailVM extends React.Component {
  constructor(props) {
    super(props);

    this.time = getTime(true, props.date);
    this.state = {
      active: false,
      date: `${getDate(props.date)} at ${this.time}`,
      displayUnreadMessage: true,
      inView: false,
      notified: false,
      read: false,
      reply: false,
      isReplyScreenVisible: false,
      isComposeScreenVisible: false,
      time: this.time,
    };

    this.ref = React.createRef();
    this.Component = this.createViewModel();
    this.toggleDetails = this.toggleDetails.bind(this);
    this.toggleReplyScreen = this.toggleReplyScreen.bind(this);
    this.toggleComposeScreen = this.toggleComposeScreen.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.poweredOn && !this.state.notified) this.alertNotification();

    if (!this.state.reply) return;

    if (
      (prevProps.view === 'mail' && this.props.view !== 'mail') ||
      (this.props.view === 'mail' && prevProps.view !== 'mail')
    ) {
      this.props.dispatch(updateInvert(false));
    }
  }

  alertNotification() {
    this.props.dispatch(updateNotifications(1));
    this.setState({ notified: true }, () => {
      new Audio('/audio/mail-alert.mp3').play();
    });
  }

  createViewModel() {
    return React.memo(Mail);
  }

  hideUnreadMessage() {
    setTimeout(() => this.setState({ displayUnreadMessage: false }), 1000);
  }

  toggleDetails() {
    if (!this.state.read) this.props.dispatch(updateNotifications(0));
    this.setState(({ active }) => ({ active: !active, read: true }), this.hideUnreadMessage);
  }

  toggleReplyScreen(bool, form) {
    this.setState({ isReplyScreenVisible: bool }, () => {
      this.props.dispatch(updateInvert(!bool, true));

      if (form && !bool) form.reset();
    });
  }

  toggleComposeScreen(bool, form) {
    this.setState({ isComposeScreenVisible: bool }, () => {
      this.props.dispatch(updateInvert(!bool, true));

      if (form && !bool) form.reset();
    });
  }

  render() {
    return (
      <this.Component
        {...this.state}
        handleBackArrowClick={this.state.active ? this.toggleDetails : () => {}}
        handleToggleDetails={this.toggleDetails}
        isSending={this.props.isSending}
        status={this.props.status}
        toggleReplyScreen={this.toggleReplyScreen}
        toggleComposeScreen={this.toggleComposeScreen}
        isScaledHeader={!!(this.state.isReplyScreenVisible || this.state.isComposeScreenVisible)}
      />
    );
  }
}

const mapStateToProps = ({ iphoneReducer, mailReducer }) => ({
  appIsActive: iphoneReducer.get('appIsActive'),
  date: mailReducer.get('date'),
  error: mailReducer.get('error'),
  isSending: mailReducer.get('isSending'),
  poweredOn: iphoneReducer.get('poweredOn'),
  status: mailReducer.get('status'),
  view: iphoneReducer.get('view'),
});

export default connect(mapStateToProps)(MailVM);

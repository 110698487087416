import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Charger from './Charger';

class ChargerVM extends React.Component {
  static defaultProps = {
    distance: null,
  };

  static propTypes = {
    distance: PropTypes.number,
  };

  static mapStateToProps({ infobarReducer }) {
    return {
      charging: infobarReducer.getIn(['battery', 'charging']),
    };
  }

  constructor(props) {
    super(props);
    this.Component = this.createViewModel();
  }

  createViewModel() {
    return connect(ChargerVM.mapStateToProps)(React.memo(Charger));
  }

  render() {
    return <this.Component {...this.props} />;
  }
}

export default ChargerVM;

import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

const BodyMessage = () => (
  <React.Fragment>
    <p className={s.message}>Hey!</p>
    <p className={s.message}>Thanks for checking out my site! How do you like it? Reply back and let me know!</p>
    <p className={s.message}>Best,</p>
    <p className={s.message}>Justin Woodward</p>
  </React.Fragment>
);

class Email extends React.Component {
  static propTypes = {
    body: PropTypes.string,
    date: PropTypes.string.isRequired,
    displayDetails: PropTypes.bool.isRequired,
    handleToggleDetails: PropTypes.func.isRequired,
    sender: PropTypes.string,
    time: PropTypes.string.isRequired,
    title: PropTypes.string,
  };

  constructor() {
    super();
    this.state = {
      read: false,
    };
    this.viewEmail = this.viewEmail.bind(this);
  }

  viewEmail() {
    this.props.handleToggleDetails();

    setTimeout(() => {
      this.setState({ read: true });
    }, 350);
  }

  render() {
    const { sender, title, body, displayDetails, date: dateStr, time } = this.props;

    return (
      <React.Fragment>
        <button className={`${s.wrapper} ${displayDetails && s.active}`} onClick={this.viewEmail}>
          <div className={s.header}>
            {!this.state.read && <div className={s.unread} />}
            <h3 className={s.sender}>{sender}</h3>
            <div className={s.date}>{time}</div>
          </div>
          <p className={s.title}>{title}</p>
          <p className={s.body}>{body}</p>
        </button>
        <div className={`${s.details} ${displayDetails && s.display}`}>
          <div className={s.detailsHeader}>
            <div className={s.info}>
              <h4 className={s.infoTitle}>{sender}</h4>
              <p>
                To: <span className={s.lighten}>You</span>
              </p>
            </div>
            <div className={s.senderIcon}>JW</div>
          </div>
          <div className={s.detailsTitle}>
            <h4 className={s.infoTitle}>{title}</h4>
            <span className={`${s.infoDate} ${s.lighten}`}>{dateStr}</span>
          </div>
          <div className={s.detailsBody}>
            <BodyMessage />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Email;

import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.scss';

const Signal = ({ invert }) => (
  <div className={`${s.container} ${invert && s.invert}`}>
    <div className={s.bar} />
    <div className={s.bar} />
    <div className={s.bar} />
    <div className={s.bar} />
  </div>
);

Signal.propTypes = {
  invert: PropTypes.bool.isRequired,
};

export default Signal;

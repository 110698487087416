import { Mail, Calendar, Bio, Music } from '../components';

export default {
  applications: [
    {
      title: 'Calendar',
      icon: '',
      url: null,
      app: null,
      readOnly: Calendar,
    },
    {
      title: 'Music',
      icon: '/images/music.jpg',
      app: Music,
      url: null,
      componentName: 'music',
    },
    {
      title: 'Bio',
      icon: '/images/justin-woodward.jpg',
      app: Bio,
      url: null,
      componentName: 'bio',
    },
    // {
    //   title: 'Calculator',
    //   icon: '/images/calculator.jpg',
    //   // app: Calculator,
    //   url: null,
    //   componentName: 'calculator',
    //   loading: true,
    // },
    // {
    //   title: 'HackerNews',
    //   icon: '/images/hackernews.jpg',
    //   // app: HackerNews,
    //   url: null,
    //   componentName: 'hackernews',
    //   loading: true,
    // },
  ],
  dock: [
    {
      icon: '/images/github.jpg',
      url: 'https://github.com/jwoo92',
      app: null,
    },
    {
      icon: '/images/linkedin.jpg',
      url: 'https://www.linkedin.com/in/justin-woodward/',
      app: null,
    },
    // {
    //   icon: '/images/fccc.jpg',
    //   url: 'https://fcccolumbus.com/',
    //   app: null,
    // },
    {
      icon: '/images/mail.jpg',
      url: null,
      app: Mail,
      componentName: 'mail',
      redux: 'mailReducer',
    },
  ],
};

const ButtonsStore = {
  buttons: [
    {
      type: {
        value: 'clear',
        group: 'clear',
      },
      content: 'AC',
      background: 'lightGrey',
    },
    {
      type: {
        value: 'plusNegative',
        group: 'operator',
      },
      content: '+/-',
      background: 'lightGrey',
    },
    {
      type: {
        value: 'percent',
        group: 'operator',
      },
      content: '%',
      background: 'lightGrey',
    },
    {
      type: {
        value: 'divide',
        group: 'operator',
      },
      content: '÷',
      background: 'orange',
    },
    {
      type: {
        value: 7,
        group: 'number',
      },
      content: '7',
      background: 'darkGrey',
    },
    {
      type: {
        value: 8,
        group: 'number',
      },
      content: '8',
      background: 'darkGrey',
    },
    {
      type: {
        value: 9,
        group: 'number',
      },
      content: '9',
      background: 'darkGrey',
    },
    {
      type: {
        value: 'multiply',
        group: 'operator',
      },
      content: '×',
      background: 'orange',
    },
    {
      type: {
        value: 4,
        group: 'number',
      },
      content: '4',
      background: 'darkGrey',
    },
    {
      type: {
        value: 5,
        group: 'number',
      },
      content: '5',
      background: 'darkGrey',
    },
    {
      type: {
        value: 6,
        group: 'number',
      },
      content: '6',
      background: 'darkGrey',
    },
    {
      type: {
        value: 'subtract',
        group: 'operator',
      },
      content: '-',
      background: 'orange',
    },
    {
      type: {
        value: 1,
        group: 'number',
      },
      content: '1',
      background: 'darkGrey',
    },
    {
      type: {
        value: 2,
        group: 'number',
      },
      content: '2',
      background: 'darkGrey',
    },
    {
      type: {
        value: 3,
        group: 'number',
      },
      content: '3',
      background: 'darkGrey',
    },
    {
      type: {
        value: 'add',
        group: 'operator',
      },
      content: '+',
      background: 'orange',
    },
    {
      type: {
        value: 0,
        group: 'number',
      },
      content: '0',
      background: 'darkGrey',
    },
    {
      type: {
        value: 'decimal',
        group: 'decimal',
      },
      content: '.',
      background: 'darkGrey',
    },
    {
      type: {
        value: 'equals',
        group: 'total',
      },
      content: '=',
      background: 'orange',
    },
  ],
};

export default ButtonsStore;

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { Button, Display } from '../../components';
import styles from './styles.module.scss';

const Calculator = ({ handleOperations, buttons, display, active }) => {
  return (
    <section className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.screen}>
          <Display display={display} />
          <div className={styles.keyPad}>
            {buttons.map((k) => (
              <Button
                key={k.get('content')}
                bg={k.get('background')}
                isActive={k.getIn(['type', 'value']) === active}
                handler={() => handleOperations(k.get('type'))}
                content={k.get('content')}
              />
            ))}
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.sensor} />
        </div>
      </div>
    </section>
  );
};

Calculator.propTypes = {
  handleOperations: PropTypes.func,
  buttons: PropTypes.instanceOf(List),
  display: PropTypes.object.isRequired,
  active: PropTypes.string,
};

export default Calculator;
